:root { 
  --black-haze: #f7f7f7;
  --bon-jour: #e0e0e0;
  --cerulean: #02a8ce;
  --downriver: #00205a;
  --iron: #d7d7d7;
  --madison: #00205c1a;
  --madison-2: #00205c;
  --mid-gray: #585f69;
  --mine-shaft: #333333;
  --white: #ffffff;
 
  --font-size-l: 18px;
  --font-size-m: 14px;
  --font-size-s: 13px;
  --font-size-xl: 24px;
  --font-size-xxl: 54px;
 
  --font-family-arialmt: "ArialMT", Helvetica;
  --font-family-open_sans: "Open Sans", Helvetica;
}
