/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

h3 {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  margin: 0 auto;
}

h3 a {
  color: #00205A;
}

html, body{
  overflow-x: hidden;
}

html, body, #root {
  height: 100%;
}
