.admin-sidebar{
    box-shadow: none !important;
}

.title-container{
    padding-bottom: 22px;
    /*background-image: linear-gradient(to top, #0086a4 5px 5px, #02a8ce 5px 17px, #ffffff 17px) !important;*/
}

.title-container h4{
    padding: 20px 0;
    padding-left: 50px;
}

.export-body{
    padding: 0 50px 0 50px;
}

.export-body .btn-disabled{
    opacity: 0.5;
    color: lightgray;
    pointer-events: none;
    cursor: default;
}