@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,300,700");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/62820f206b0474872f573c9a.62820f23cfc3fc7b067050a4.mmvx8yg.rcp.png");


.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  /*flex-shrink: 0;*/
  pointer-events: auto;
}

.overlay {
  display: none;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.overlay.animate-appear {
  animation: reveal 0.3s ease-in-out 1 normal forwards;
  display: block;
  opacity: 0;
}

.overlay.animate-disappear {
  animation: reveal 0.3s ease-in-out 1 reverse forwards;
  display: block;
  opacity: 1;
  pointer-events: none;
}

.overlay.animate-disappear * {
  pointer-events: none;
}

* {
  box-sizing: border-box;
}

.navbar-header {
  height: 36px;
  position: relative;
  top: -14px;
  margin-right: auto !important;
}


@media only screen and (max-width: 600px) {
  #overlay-grp img {
    display: none;
  }

  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  #overlay-grp > div {
    padding: 30px;
  }

  /*header*/
  #overlay-grp > div > div:first-child {
    font-size: 40px;
    line-height: unset;
    
  }
  
  #overlay-grp > div > div, #overlay-grp > div {
    max-width: unset !important;
    width: unset !important;
  }

  #footer .footer {
    padding: 40px 20px;
  }
  
  #copyright {
    padding: 0px 20px;
  }
  
  #search-form {
    width: 100% !important;
  }
  
  .landingpage {
    width: 100%;
  }
  
  .navbar-tagline {
  }
  
  .navbar {
    min-height: 125px !important;
    margin-left: 15px !important;
  }
}