.landingpage {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /*min-height: 1030px;*/
  max-width: 1564px;
}

.current-sku-dropdown{
  width: 100%;
}

.current-sku-text-field label,
.current-sku-text-field input,
.current-sku-text-field span{
  font-family: "Open Sans", Helvetica;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333;
}

.french-text-field,
.gauge-text-field{
  width: 100%;
}

.french-text-field label,
.french-text-field input,
.french-text-field span,
.gauge-text-field label,
.gauge-text-field input,
.gauge-text-field span
{
  font-family: "Open Sans", Helvetica;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333;
}

label.Mui-focused{
  color: #00A9CE !important;
}

.Mui-focused fieldset {
  border-color: #00A9CE !important;
}



