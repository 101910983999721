.results-table-header th {
    color: var(--mid-gray);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 700;
    font-style: normal;
    min-height: 21px;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
}

.results-table-rows td{
    color: var(--mine-shaft);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    min-height: 21px;
    margin-top: 31px;
    margin-left: 1px;
    letter-spacing: 0;
    line-height: 21px;
    white-space: nowrap;
}
.results-table-rows-leader td{
    color: var(--mine-shaft);
    font-family: var(--font-family-open_sans);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
    min-height: 21px;
    margin-top: 31px;
    margin-left: 1px;
    letter-spacing: 0;
    line-height: 21px;
    border-top: 2px solid rgba(224, 224, 224, 1);
    border-top-color: rgba(224, 224, 224, 1);
    white-space: nowrap;
}
